<template>
  <div class="home">
    <div class="content">
      <div class="container">
        <div class="title">公司介绍</div>
        <div class="introduction">
          <span style="color: #4f9faa;">HN Mini Games</span>
          帮助各种规模的，移动游戏开发者进行游戏的制作、发行和推广，促进业务的发展和增长。
        </div>
        <div class="title">特色产品</div>
        <div class="product">
          <ul>
            <li v-for="item of game" :key="item.id">
              <div class="game">
                <div class="game_icon">
                  <img :src="item.icon" />
                </div>
                <div class="game_text">
                  <h4 class="game_title">{{ item.title }}</h4>
                  <p class="game_introduce" v-html="item.introduce"></p>
                </div>
              </div>
              <div class="game_img" :class="{ game_img2: item.id == 6 }">
                <img
                  v-for="(item, index) of item.gameImg"
                  :key="index"
                  :src="item"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="cooperation">
        <div class="text_1">合作心声</div>
        <div class="text_2">助力开发者，成就榜首梦</div>
        <div class="text_2">改变与成长，共创繁荣共享丰盛</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      game: [
        {
          id: 6,
          icon: require('@/assets/img/一起来打架/icon.jpg'),
          title: '《 征服荒漠大陆手游 》',
          introduce:
            '<span style="display: inline-block;width: 2em;"></span>纷争乱世的背景下，召集你的军队，抵抗入侵，征服这篇大陆吧！',
          gameImg: [
            require('@/assets/img/一起来打架/1.png'),
            require('@/assets/img/一起来打架/2.png'),
            require('@/assets/img/一起来打架/3.png'),
            require('@/assets/img/一起来打架/4.png'),
            require('@/assets/img/一起来打架/5.png'),
            require('@/assets/img/一起来打架/6.png'),
            require('@/assets/img/一起来打架/7.png'),
            require('@/assets/img/一起来打架/8.png'),
            require('@/assets/img/一起来打架/9.png'),
            require('@/assets/img/一起来打架/10.png'),
            require('@/assets/img/一起来打架/11.png'),
            require('@/assets/img/一起来打架/12.png'),
            require('@/assets/img/一起来打架/13.png'),
            require('@/assets/img/一起来打架/14.png')
          ]
        },
        {
          id: 5,
          icon: require('@/assets/img/萌宠训练师/icon.png'),
          title: '《 萌宠训练师 》',
          introduce:
            '<span style="display: inline-block;width: 2em;"></span>被选中的守护者，快来拯救喵界吧！</br><span style="display: inline-block;width: 2em;"></span>《 萌宠训练师 》是一款萌系猫咪题材的策略放置手游，讲述了祥和的喵界遭到食梦魔入侵，玩家被选为喵界守护者来到喵界带领可爱的小猫咪们对抗食梦魔维护喵界和平的故事。区别于其他游戏，《 萌宠训练师 》的画面采用了萌系3D的独特风格，让人耳目一新！玩法上颠覆传统放置，让策略玩法与不肝融入了游戏，大量的资源产出以及趣味玩法让玩家轻松畅玩；除此之外，游戏还有超强福利的游戏生态，快来一起撸猫畅玩吧！',
          gameImg: [
            require('@/assets/img/萌宠训练师/1.png'),
            require('@/assets/img/萌宠训练师/2.png'),
            require('@/assets/img/萌宠训练师/3.png'),
            require('@/assets/img/萌宠训练师/4.png'),
            require('@/assets/img/萌宠训练师/5.png'),
            require('@/assets/img/萌宠训练师/6.png'),
            require('@/assets/img/萌宠训练师/7.png'),
            require('@/assets/img/萌宠训练师/8.png'),
            require('@/assets/img/萌宠训练师/9.png'),
            require('@/assets/img/萌宠训练师/10.png')
          ]
        }

        // {
        //   id: 1,
        //   icon: require('@/assets/img/荒古奇谭/icon.png'),
        //   title: '《 荒古奇谭手游 》',
        //   introduce:
        //     '<span style="display: inline-block;width: 2em;"></span>神兽袭来！荒古奇谭是一款特别的神兽养成游戏，通过神兽之间的不断合成，养成更高的神兽，还有神将助阵，快去打造你的神兽军团吧！',
        //   gameImg: [
        //     require('@/assets/img/荒古奇谭/1.png'),
        //     require('@/assets/img/荒古奇谭/2.png'),
        //     require('@/assets/img/荒古奇谭/3.png')
        //   ]
        // },
        // {
        //   id: 2,
        //   icon: require('@/assets/img/异世冒险之旅/icon.png'),
        //   title: '《 异世冒险之旅 》',
        //   introduce:
        //     '<span style="display: inline-block;width: 2em;"></span>跌落梦境的你，需要如何逃离？<br/><span style="display: inline-block;width: 2em;"></span>魔王将它的魂器，隐藏在世界各地各地的迷宫之中，引诱不够强大的挑战者深陷其中，在迷宫中阵亡的挑战者会成为魔王的随从，迷宫的守卫，壮大魔王的力量。<br/><span style="display: inline-block;width: 2em;"></span>迷宫中拥有大量的财宝，哪怕没有击败魔王的崇高理想，只是为了无尽的财富，也不断有人去挑战迷宫。<br/><span style="display: inline-block;width: 2em;"></span>每当魔王苏醒的时候，神谕者就会去寻找新的勇者，以拯救世界，玩家就是那个被挑选中的人，玩家需要挑战不同的迷宫，获得素材，建造自己的营地，解救其他的职业伙伴，提升自己团队的力量，直到击败魔王。',
        //   gameImg: [
        //     require('@/assets/img/异世冒险之旅/1.png'),
        //     require('@/assets/img/异世冒险之旅/2.png'),
        //     require('@/assets/img/异世冒险之旅/3.png')
        //   ]
        // },
        // {
        //   id: 3,
        //   icon: require('@/assets/img/英灵对决/icon.png'),
        //   title: '《 英灵对决 》',
        //   introduce:
        //     '<span style="display: inline-block;width: 2em;"></span>英灵对决是一款随机合成PVP对战塔防游戏，游戏包含1V1对战，BOSS消除，合成卡牌及塔防守护等多种游戏要素的休闲游戏。',
        //   gameImg: [
        //     require('@/assets/img/英灵对决/1.jpg'),
        //     require('@/assets/img/英灵对决/2.jpg'),
        //     require('@/assets/img/英灵对决/3.jpg')
        //   ]
        // },
        // {
        //   id: 4,
        //   icon: require('@/assets/img/合并战争/icon.png'),
        //   title: '《 合并战争 》',
        //   introduce:
        //     '<span style="display: inline-block;width: 2em;"></span>卡通合并策略游戏，合理制定你的防御策略，赢得每一次对战的胜利吧！ 操控你的军团，成为这边土地的军团之王吧！！',
        //   gameImg: [
        //     require('@/assets/img/合并战争/1.jpg'),
        //     require('@/assets/img/合并战争/2.jpg'),
        //     require('@/assets/img/合并战争/3.jpg')
        //   ]
        // }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.introduction {
  padding: 30px 50px;
  text-indent: 2em;
  font-size: 28px;
  border-bottom: 4px solid @blColor;
}
.title {
  margin-top: 36px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: rgb(85, 85, 85);
}
.cooperation {
  width: 100%;
  background: url(~@/assets/img/bg.jpg) no-repeat;
  background-position: center;
  text-align: center;
  padding: 120px 0 240px;
  color: #fff;
  .text_1 {
    font-size: 48px;
  }
  .text_2 {
    margin-top: 30px;
    font-size: 42px;
  }
}
.product {
  li {
    padding: 50px 0 100px 0;
    border-bottom: 4px solid @blColor;
    &:last-child {
      border-bottom: 0px solid @blColor;
    }
    .game {
      display: flex;
      .game_icon {
        margin: 40px;
        border-radius: 100%;
        overflow: hidden;
        width: 280px;
        height: 280px;
        img {
          width: 280px;
          height: 280px;
          // border-radius: 100%;
          // background-position: center 100%;
          // object-fit: cover;
          background-size: 100% 100%;
          transform: scale(1.1);
          transition: all 0.6s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .game_text {
        width: 860px;
        padding-top: 40px;
        padding-left: 80px;
        .game_title {
          font-size: 28px;
          opacity: 1;
          font-weight: bold;
        }
        .game_introduce {
          margin-top: 20px;
          font-size: 24px;
          opacity: 1;
          font-weight: normal;
        }
      }
    }
    .game_img {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      img {
        width: 386px;
        height: 686px;
        margin-top: 20px;
        margin-right: 20px;
        &:nth-child(3n) {
          margin-right: 0px;
        }
      }
    }
    .game_img2 {
      img {
        height: 217px;
      }
    }
  }
}
</style>
